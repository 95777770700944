import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["toggle"]
  
  connect() {
    this.MAIN = 'main' // See Picture::Tags::MAIN
  }
  
  onToggleClick(event) {
    const line = event.currentTarget.closest('li')
    const tag = event.currentTarget.dataset.tag
  
    this.setTag(line, tag)
    if (tag === this.MAIN) {
      this.element.prepend(line)
    }
  }
  
  setTag(line, tag) {
    this.resetTag(tag)
    line.dataset.tag = tag
    if (tag && line.querySelector('[data-role=picture-tag]')) {
      line.querySelector('[data-role=picture-tag]').value = tag
    }
  }
  
  resetTag(tag) {
    this.element.querySelectorAll('[data-role=picture-tag]').forEach(el => {
      if (el.value === tag) el.value = ''
    })
    this.element.querySelectorAll(`li[data-tag="${tag}"]`).forEach(el => {
      el.dataset.tag = ''
    })
  }
  
  setFirstAsMain() {
    const first = this.element.querySelector('li')
    this.setTag(first, this.MAIN)
  }
}
