import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    progressUrl: String,
    playButtonUrl: String
  }

  POLLING_FREQUENCY_MS = 4000
  completed = false
  pollingStopped = false

  connect() {
    this.pollingStopped = false
    this.poll()
  }

  disconnect() {
    this.pollingStopped = true
  }

  poll() {
    if (this.completed || this.pollingStopped) return
    this.update()
    setTimeout(() => this.poll(), this.POLLING_FREQUENCY_MS)
  }

  update() {
    fetch(this.progressUrlValue)
      .then(response => {
        if (response.status === 202) {
          return response.text()
        } else {
          this.completed = true
          return response.text()
        }
      })
      .then(html => {
        this.element.innerHTML = html

        if (this.completed) {
          this.addPlayButton()
        }
      })
      .catch(() => {
        this.completed = true
        this.element.innerHTML = ''
      })
  }

  addPlayButton() {
    let self = this
    fetch(this.playButtonUrlValue)
      .then(response => response.text())
      .then(html => {
        self.element.closest('.group\\/track').querySelector('.play-button').innerHTML = html
      })
  }
}
