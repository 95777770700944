export default class PicturePreviewCache {
  static previews = {} // Initializes an empty object to store previews

  // Static method to add a new preview to the cache
  static add(id, preview) {
    this.previews[id] = preview
  }

  // Static method to retrieve a preview from the cache
  static get(id) {
    return this.previews[id]
  }
}
