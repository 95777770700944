// uploader.js

import Resumable from '../../assets/javascripts/admin/lib/resumable'
 
export default class Uploader {
  constructor(options) {
    this.options = { ...options }  // Use object spread instead of jQuery's $.extend
    this.completed = false
  }

  init() {
    this.initResumable()
    this.bindEvents()
  }

  initResumable() {
    this.resumable = new Resumable({
      target: this.options.uploadPath,
      fileTypeErrorCallback: this.resumableFileTypeErrorCallback.bind(this),
      maxFileSizeErrorCallback: this.resumableMaxFileSizeErrorCallback.bind(this),
      generateUniqueIdentifier: this.generateUniqueIdentifier.bind(this),
      headers: { 'X-CSRF-Token': this.options.formAuthToken },
      fileType: this.options.fileType,
      maxFileSize: this.options.maxFileSize,
    })

    if (!this.resumable.support) {
      this.dispatchCustomEvent('unsupported-browser')
    } else {
      this.resumable.assignBrowse(this.options.dropzone)
      this.resumable.assignDrop(this.options.dropzone)
    }
  }

  bindEvents() {
    this.resumable.on('fileAdded', this.onResumableFileAdded.bind(this))
    this.resumable.on('progress', this.onResumableProgress.bind(this))
    this.resumable.on('fileSuccess', this.onResumableFileSuccess.bind(this))
    this.resumable.on('error', this.onResumableError.bind(this))
  }

  onResumableFileAdded(resumableFile, event) {
    this.resumable.upload()
    this.dispatchCustomEvent('start', { resumableFile })
  }

  onResumableProgress() {
    this.dispatchCustomEvent('progress', {
      uploadId: this.resumable.files[0].uniqueIdentifier,
      progress: this.resumable.progress(),
    })
  }

  onResumableFileSuccess() {
    this.completed = true
    this.dispatchCustomEvent('complete', {
      uploadId: this.resumable.files[0].uniqueIdentifier,
    })
  }

  onResumableError() {
    this.dispatchCustomEvent('error')
  }

  resumableFileTypeErrorCallback(resumableFile, errorCount) {
    this.dispatchCustomEvent('unsupported-file-type')
  }

  resumableMaxFileSizeErrorCallback(resumableFile, errorCount) {
    this.dispatchCustomEvent('exceeded-max-file-size')
  }

  generateUniqueIdentifier(file) {
    const relativePath = file.webkitRelativePath || file.fileName || file.name
    return `${this.options.userId}-${file.size}-${relativePath.replace(/[^0-9a-zA-Z_-]/g, '')}`
  }

  dispatchCustomEvent(eventName, detail = {}) {
    const event = new CustomEvent(eventName, { detail })
    this.options.dropzone.dispatchEvent(event)
  }
}
