export default class Playlist {
  constructor({ tracks, name, path, selected = 0 }) {
    this.tracks = tracks
    this.name = name
    this.path = path
    this.index = selected
    this.type = 'Playlist'
  }

  skipForward() {
    this.index += 1
  }

  skipToTrack(index) {
    this.index = index
  }

  skipBackward() {
    this.index -= 1
  }

  hasNext() {
    return this.index < (this.tracks.length - 1)
  }

  hasPrevious() {
    return this.index > 0
  }

  get current() {
    if (this.tracks.length > 0) {
      return this.tracks[this.index]
    } else {
      return null
    }
  }

  set index(value) {
    this._index = clamp(value, 0, this.tracks.length - 1)
  }

  get index() {
    return this._index
  }
}

const clamp = (number, min, max) => Math.min(Math.max(number, min), max)
