import { Controller } from "@hotwired/stimulus"
import Sortable, { AutoScroll } from 'sortablejs/modular/sortable.core.esm.js'
Sortable.mount(new AutoScroll())

export default class extends Controller {
  static targets = ['list', 'item']
  static values = { url: String, sortable: Boolean }

  initialize() {
    let self = this
    if (!this.hasListTarget) return
    let param = 'track'
    if (self.itemTargets.length && self.itemTargets[0].getAttribute('data-playlist-id')) {
      param = 'playlist'
    }

    if (this.sortableValue) {
      new Sortable(this.listTarget, {
        animation: 150,
        onEnd: function () {
          let newOrder = Array.from(self.itemTargets).map(item => 
            `${param}[]=${item.getAttribute(`data-${param}-id`)}`
          ).join('&')
          self.saveOrder(newOrder, self.urlValue)
        }
      })
    }
  }

  async saveOrder(newOrder, href) {
    const response = await fetch(href, {
      method: 'PUT',
      body: newOrder,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    })

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`)
    }
    return response
  }

  deletePlaylist(event) {
    event.preventDefault()
    let self = this
    if (confirm(event.target.dataset.confirm)) {
      this.deleteItem(event.target.getAttribute('href')).then(() => {
        self.onDestroySuccess(event)
      })
    }
  }

  removeTrackFromPlaylist(event) {
    this.removeItem(event)
  }

  removePlaylistFromPlaylistCollection(event) {
    this.removeItem(event)
  }

  removeItem(event) {
    event.preventDefault()
    let self = this
    this.deleteItem(event.target.getAttribute('href')).then(() => {
      self.onDestroySuccess(event)
    })
  }

  onDestroySuccess(event) {
    event.stopPropagation()
    event.target.closest(".group\\/track").remove()
  }

  async deleteItem(href) {
    const response = await fetch(href, {
      method: 'DELETE',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    })

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`)
    }
    return response
  }
}
