import { Controller } from "@hotwired/stimulus"
import Uploader from "../helpers/uploader"

export default class extends Controller {
  static targets = ["dropzone", "uploading", "formContainer", "unsupportedBrowserMessage", "unsupportedFileTypeMessage", "removableElementsOnUpload"]
  static values = {
    acceptedExtensions: String,
    options: String
  }

  connect() {
    this.TRACK_FILE_EXTENSIONS = JSON.parse(this.acceptedExtensionsValue)
    this.options = JSON.parse(this.optionsValue)
    this.initUi()
    this.initUploader()
    this.uploader.init()
  }

  initUi() {
    this.ui = {
      dropzone: this.dropzoneTarget,
      upload: this.uploadTarget,
      formContainer: this.formContainerTarget,
      unsupportedBrowserMessage: this.unsupportedBrowserMessageTarget,
      unsupportedFileTypeMessage: this.unsupportedFileTypeMessageTarget,
      removableElementsOnUpload: this.removableElementsOnUploadTargets,
      progressBarContainer: this.progressBarContainerTarget,
      progressBarInner: this.progressBarInnerTarget
    }
  }

  initUploader() {
    this.uploader = new Uploader({
      dropzone: this.ui.dropzone,
      uploadPath: this.options.uploadPath,
      fileType: this.TRACK_FILE_EXTENSIONS,
      formAuthToken: this.options.formAuthToken,
      userId: this.options.userId
    })
  }

  start(event) {
    this.stateUploading()
    this.trackEdit(event.detail.resumableFile)
  }

  progress(event) {
    const data = event.detail
    if (this.ui.progressBarInner) {
      this.ui.progressBarInner.style.width = `${data.progress * 100}%`
    }
  }

  complete() {
    this.stateUploadCompleted()
  }

  error(event) {
    console.error('error on upload', event.detail)
  }

  trackEdit(resumableFile) {
    const params = new URLSearchParams({
      file_identifier: resumableFile.uniqueIdentifier,
      original_filename: resumableFile.fileName,
      file_size: resumableFile.size,
      file_type: resumableFile.type,
      chunks_count: resumableFile.chunks.length
    })

    const urlWithParams = `${this.options.formPath}?${params.toString()}`
    fetch(urlWithParams, {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': this.options.formAuthToken
      },
      credentials: 'same-origin',
    })
      .then(response => response.text())
      .then(responseText => this.processTrackEditResponse(responseText))
  }

  processTrackEditResponse(responseText) {
    const responseElement = document.createElement('div')
    responseElement.innerHTML = responseText
    document.querySelectorAll('[data-track-upload-target="removableElementsOnUpload"]').forEach(element => element.remove())
    document.querySelectorAll('[data-track-upload-target="hidableElementsOnUpload"]').forEach(element => element.classList.add('hidden'))

    if (responseElement.querySelector('form')) {
      this.formContainerTarget.innerHTML = responseText
      this.initTrackForm()
    } else {
      this.newUpload(responseText)
    }
  }

  newUpload(responseText) {
    this.element.parentElement.innerHTML = responseText
  }

  initTrackForm() {
    this.ui.form = this.ui.formContainer.querySelector('form')
    this.ui.progressBarContainer = this.ui.formContainer.querySelector('[data-role=progress-bar-container]')
    this.ui.progressBarInner = this.ui.formContainer.querySelector('[data-role=progress-bar-inner]')

    if (this.uploader.completed) this.stateUploadCompleted()

    this.bindTrackFormEvents()
  }

  bindTrackFormEvents() {
    this.ui.formContainer.addEventListener('unsupported_media_type', (event) => {
      this.newUpload(event.detail.response)
    })
  }

  stateUnsupportedBrowser() {
    this.ui.unsupportedBrowserMessage.style.display = 'block'
    this.ui.dropzone.style.opacity = 0
  }

  stateUnsupportedFileType() {
    this.ui.unsupportedFileTypeMessage.style.display = 'block'
  }

  stateUploading() {
    this.ui.unsupportedFileTypeMessage.style.display = 'none'
    this.uploadingTarget.classList.remove('hidden')
    this.ui.dropzone.style.opacity = 0
  }

  stateUploadCompleted() {
    if (this.ui.progressBarContainer) {
      this.ui.progressBarContainer.classList.add('completed')
    }
  }

  delete(event) {
    event.preventDefault()
    let self = this
    if (confirm(event.target.dataset.confirm)) {
      this.deleteItem(event.target.getAttribute('href'))
        .then(() => {
          self.onTrackDestroySuccess(event)
        })
    }
  }

  onTrackDestroySuccess(event) {
    event.stopPropagation()
    event.target.closest(".group\\/track").remove()
  }

  async deleteItem(href) {
    const response = await fetch(href, {
      method: 'DELETE',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    })

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`)
    }
    return response
  }
}
