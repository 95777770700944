export default class Track {
  constructor(track) {
    this.current = track
    this.type = 'Track'
  }

  hasNext() {
    return false
  }

  hasPrevious() {
    return false
  }
}
