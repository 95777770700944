import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dropdown", "input"]

  connect() {
    this.dropdownTarget.querySelector('a').addEventListener('click', () => this.hideImmediately())
  }

  show() {
    this.dropdownTarget.classList.remove("hidden")
  }

  hide(event) {
    setTimeout(() => {
      if (!this.element.contains(document.activeElement)) {
        this.dropdownTarget.classList.add("hidden")
      }
    }, 200)
  }

  hideImmediately() {
    this.dropdownTarget.classList.add("hidden")
  }

  disconnect() {
    const link = this.dropdownTarget.querySelector('a')
    if (link) {
      link.removeEventListener('click', () => this.hideImmediately())
    }
  }
}
