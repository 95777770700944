import { Controller } from "@hotwired/stimulus"
import lodash from "lodash"

import Tagify from '@yaireo/tagify'
import '@yaireo/tagify/dist/tagify.css'

export default class extends Controller {
  static values = { url: String, multiple: Boolean, displayKey: String, enforceWhitelist: Boolean }
  static tagify = null

  connect() {
    this.element.ignoreFormTracker = true    
    this.element.ignoreFormTracker = null
    requestAnimationFrame(() => {
      this.initializeTagInput()
    })
  }
  disconnect() {
    if (this.tagify) {
      this.tagify.destroy()
      this.tagify = null
    }
  }

  initializeTagInput() {
    let input = this.element
    let config = {
      whitelist:[],
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(',')
    }
    if (!this.multipleValue) config.mode = 'select'
    if (this.enforceWhitelistValue) {
      config.addTagOnBlur = false
    } 
    if (this.displayKeyValue) {
      config.dropdown = {
        enabled: false,
        searchKeys: [this.displayKeyValue]
      }
    }
    this.tagify = new Tagify(input, config)
    let elemClasses = 'after:hidden !after:content-none w-full h-12 flex !items-center leading-5 relative py-2 px-4 rounded bg-neutral-10 !dark:bg-neutral-900 border focus:border-2 border-gray-500 overflow-x-auto focus:outline-none focus:border-primary-600 focus:ring-0 dark:text-gray-200 dark:border-gray-400 dark:focus:border-primary-200 peer'
    if (this.tagify.DOM) this.tagify.DOM.scope.classList.add(...elemClasses.split(' '))
    let controller
    let self = this

    const debouncedFetch = lodash.debounce(function(tagify, self, value) {
      tagify.whitelist = null
      controller && controller.abort()
      controller = new AbortController()
      tagify.loading(true)
    
      fetch(self.urlValue.replace('%25', '%').replace('%QUERY', value), { signal: controller.signal })
        .then(RES => RES.json())
        .then(function(newWhitelist) {
          let updatedData = newWhitelist
          if (self.displayKeyValue) {
            updatedData = updatedData.map(item => {
              let { [self.displayKeyValue]: value, ...rest } = item
              return { value, ...rest }
            })
          }
          tagify.whitelist = updatedData
          tagify.loading(false).dropdown.show(value)
        })
        .catch(err => {
          if (err.name !== 'AbortError') console.error('Fetch error:', err)
        })
    }, 500)

    this.tagify.on('input', (event) => {
      const value = event.detail.value
      if (value.length < 2) return
      debouncedFetch(this.tagify, self, value)
    })
  }
}
