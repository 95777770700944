import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['field', 'form']
  static values = {
    message: String
  }

  connect() {
    this.message = ''
    this.bindEvents()
    this.reset()
    this.element.dataset.tracker = this

    document.addEventListener("turbo:before-visit", (event) => {
      if (this.message) {
        const userConfirmed = confirm(this.messageValue)
        if (!userConfirmed) {
          event.preventDefault()
        } else {
          this.reset()
        }
      }
    })
  }

  bindEvents() {
    // Add change listeners to all form fields and submit listener to form
    this.element.querySelectorAll('input, select, textarea').forEach(field => {
      field.addEventListener('change', this.onChange.bind(this))
    })
    this.element.addEventListener('submit', this.onSubmit.bind(this))
  }

  onChange(event) {
    if (!event.target.ignoreFormTracker) {
      this.markAsDirty()
    }
  }

  onSubmit() {
    this.reset()
  }

  markAsDirty(msg) {
    this.message = msg || this.messageValue
    window.addEventListener('beforeunload', this.beforeUnloadHandler.bind(this))
  }

  beforeUnloadHandler(event) {
    if (this.message) {
      event.preventDefault()
      event.returnValue = this.message  // Standard for beforeunload event
      return this.message
    }
  }

  reset() {
    this.message = ''
    window.removeEventListener('beforeunload', this.beforeUnloadHandler.bind(this))
  }

  confirmationMessage() {
    return this.message
  }
}
